<template>
  <div>
    <Modal
      :value="value"
      title="设备心跳"
      @input="(val) => $emit('input', val)"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="心跳名称" prop="newName">
          <Input v-model="form.newName" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="心跳guid" prop="newGuid">
          <Input v-model="form.newGuid" style="width: 300px"></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" :loading="submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    oldGuid: {
      type: String,
      default: "",
    },
    oldName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        newGuid: "",
        newName: "",
      },
      rules: {
        newGuid: [{ required: true, message: "请填写心跳guid" }],
        newName: [{ required: true, message: "请填写心跳名称" }],
      },
      submitLoading: false,
    };
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.form.newGuid = this.oldGuid;
        this.form.newName = this.oldName;
      } else {
        this.form = {
          newGuid: "",
          newName: "",
        };
        this.$refs.form.resetFields();
      }
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.oldGuid = this.oldGuid;
        this.submitLoading = true;
        this.$post(this.$api.BUSSDEVICELUOLA.UPDATEGUID, params)
          .then(() => {
            this.$emit("update-success");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>